html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}
:root {
    /* Colors */
    --h1-color: #fff;
    --h2-color: #86b6df;
    --site-header-color: #fff;
    --content-color: #6f6c70;
    --content-title-color: #595959;

    /* Header sizes */
    --h1-size: 48px;
    --h1-size-mobile: 30px;
    --h2-size: 33px;
    --h2-size-mobile: 25px;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.scroll-to-link * {
    pointer-events: none;
}

@media (min-width: 768px) {
    .container {
        padding: 0 20px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 72px;
    /* margin-bottom: 72px; */
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 900;
    font-size: 42px;
    line-height: 62px;
    margin-bottom: 25px;
}

p {
    font-size: 16px;
    line-height: 30px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

/* Banner */
.banner {
    background: #ffffff;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center;
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 10;
}
.banner .logo-box {
    background: #eaf2f8;
    border-radius: 10px;
}
.banner .logo-box a {
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7d7f82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #f27558;
}
.banner .domaner-btn {
    background: #f27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title {
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
@media only screen and (max-width: 940px) {
    .banner {
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a {
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title {
        line-height: 24px;
        text-align: center;
    }
    .banner .info {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before {
        content: none;
    }
}
@media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
}

.site-logo {
    background: url('../image/logo.webp');
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
    background-size: 205px;
    width: 205px;
    background-position-y: -30px;
}

.logo-wrapper {
    padding-top: 0px;
    padding-bottom: 22px;
    display: flex;
    justify-content: center;
    height: 161px;
    position: relative;
    z-index: 1;
}
.site-header {
    color: #fff;
    background-color: #aed5f6;
    position: relative;
    overflow: hidden;
}
.site-header-inner {
    /* background-color: #111D4F;
    border-radius: 29px; */
    position: relative;
    padding: 45px 60px 105px;
    text-align: center;
}

.header-image {
    position: absolute;
    right: 15px;
    bottom: 0;
    /* background-image: url("../image/radial-gradient.png");
    width: 1373px;
    height: 1263px; */
    /* TODO */
}

.hero-image {
    position: relative;
}

.image-wrapper {
    width: 50%;
    position: absolute;
    background-image: url('../image/hero-image.webp');
    width: 2082px;
    height: 1800px;
    top: -765px;
    left: 193px;
}

.header-description {
    /* margin-bottom: 60px; */
    width: 487px;
    margin: 0 auto 100px;
}

.nav-wrapper {
    width: 915px;
    position: relative;
    font-size: 17px;
    font-weight: 700;
    margin: 0 auto;
}

.nav-wrapper-bg {
    background-color: #fff;
    /* padding: 10px 30px; */
    border-radius: 69px;
    opacity: 0.15;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.cta-btns {
    position: relative;
    z-index: 2;
    padding: 15px 50px;
}

.btn-lis a {
    color: white;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center left;
    position: relative;
}
span {
    position: relative;
}
p:not(:last-child) {
    margin-bottom: 25px;
}
.steps p {
    margin-bottom: 0;
}

.cta-btns li:first-of-type span:before {
    background-image: url('../image/tool.webp');
    left: -25px;
    top: 2px;
}
.cta-btns li:nth-of-type(2) span:before {
    background-image: url('../image/features.webp');

    left: -25px;
    top: 2px;
}
.cta-btns li:nth-of-type(3) span:before {
    background-image: url('../image/create.webp');
    top: 4px;
    left: -25px;
    top: 6px;
}
.cta-btns li:nth-of-type(4) span:before {
    background-image: url('../image/sell.webp');
    left: -25px;
    top: 2px;
}
.cta-btns li a:hover span::before {
    filter: invert(54%) sepia(9%) saturate(2350%) hue-rotate(294deg) brightness(89%) contrast(87%);
}
.cta-btns span::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    left: -25px;
    top: 2px;
    transition: filter 0.2s ease-in-out;
}

nav ul li a:hover {
    color: #c1687f;
    transition: color 0.2s ease-in-out;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    display: flex;
    justify-content: space-around;
}

.nav-wrapper li {
    margin-right: 31px;
}

/* H2-HEADERS */
.h2-header {
    margin-top: 79px;
    color: var(--h2-color);
    font-size: var(--h2-size);
    font-weight: 700;
    text-align: center;
}

.articles {
    align-items: center;
    text-align: center;
}
.articles p {
    color: var(--content-color);
    line-height: 26px;
    font-size: 15px;
}

.navigation-articles-desktop {
    max-width: 1287px;
    box-shadow: 0px 0px 25px 0px #00000014;
    border-radius: 20px;
    padding: 12px;
    margin-top: 65px;
}
.navigation-tabs {
    width: 100%;
}
.navigation-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
}

.navigation-grid li {
    text-align: center;
    padding: 25px 12px 0;
    border-radius: 20px 20px 0 0;
}
.grey-bg {
    background-color: #f2f2f2;
}
.tab-1,
.tab-2,
.tab-3 {
    color: var(--content-title-color);
}

.content-title {
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 20px;
    margin-top: 45px;
    color: var(--content-title-color);
}

.content {
    text-align: center;
    font-size: 14px;
    color: var(--content-color);

    /* position: absolute;
    transform: translateX(150%); */
}

.navigation-content {
    padding: 30px 20px;
    text-align: center;
    line-height: 30px;
    border-radius: 0 0 22px 22px;
    /* background-color: #f2f2f2; */

    /* position: relative;
    overflow: hidden; */
}
.tablinks {
    cursor: pointer;
}
.tabcontent p {
    color: #6f6c70;
}
.tabcontent li {
    color: #6f6c70;
}

.tablinks.active {
    background-color: #f2f2f2;
    transition: 0.3s;
}
.tablinks * {
    pointer-events: none;
}
.tablinks:hover h3 {
    color: #dda8ba;
}

.tabcontent {
    display: none;
}
.tabcontent.active {
    display: block;
}
.tab-img1 {
    background-image: url('../image/draw-icon.webp');
    width: 167px;
    height: 176px;
    background-position: center;
    margin: 0 auto;
    background-repeat: no-repeat;
}
.tab-img2 {
    background-image: url('../image/generator-icon.webp');
    width: 167px;
    height: 182px;
    background-position: center;
    margin: 0 auto;
    background-repeat: no-repeat;
}
.tab-img3 {
    background-image: url('../image/mint-icon.webp');
    width: 167px;
    height: 167px;
    background-position: center;
    margin: 0 auto;
    background-repeat: no-repeat;
}

.features-content {
    max-width: 1287px;
    box-shadow: 0px 0px 25px 0px #00000014;
    border-radius: 20px;
    margin-top: 53px;
    padding: 34px 40px 40px;
}
.features-content-description {
    font-size: 14px;
    color: var(--content-color);
    line-height: 26px;
    margin-top: 20px;
}
.features-headers {
    font-size: 22px;
    color: var(--content-title-color);
    text-transform: uppercase;
    margin-left: 23px;
    position: relative;
}
.features-headers::before {
    content: '';
    width: 45px;
    height: 45px;
    background-color: #e1a4b433;
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    top: -8px;
    left: -24px;
}

.steps {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
}
.steps li {
    display: flex;
    position: relative;
    align-items: center;
}
.steps li svg {
    height: 3px;
    margin-left: 16px;
    margin-right: 16px;
}
/* .steps li::after{
    content: "";
    position: absolute;
    width: 11px;
    height: 1px;
    background-color: red;
} */
.steps li p {
    color: #78b3e5;
    font-size: 15px;
    font-weight: 600;
}
.steps li img {
    width: 30px;
    height: 30px;
    margin-right: 11px;
}
.create-nft {
    text-align: center;
}
.create-nft-content {
    font-size: 15px;
    color: var(--content-color);
    line-height: 26px;
    width: 95%;
    margin: 0 auto;
    text-align: center;
}

.best-marketplace-wrapper {
    display: flex;
    align-items: center;
}
.our-team-wrapper {
    background-color: #f5f4f7;
}

.best-marketplace {
    padding-bottom: 50px;
}

.wrapper-inner {
    padding-top: 88px;
    padding-bottom: 105px;
    text-align: center;
}
.wrapper-inner h2 {
    font-size: var(--h2-size);
    color: var(--h2-color);
    text-align: center;
}
.bullet-points {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--content-color);
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 15px;
}
.bullet-points p {
    padding-left: 5px;
    position: relative;
    line-height: 25px;
}
.bullets {
    margin-top: 55px;
    position: relative;
}
.w {
    position: relative;
}
.w::before {
    position: absolute;
    content: '•';
    font-size: 25px;
    color: #86b6df;
    left: -16px;
}

.faq-wrapper-outer {
    background-color: #86b6e0;
    padding-top: 85px;
    overflow: hidden;
}
.bg-gradient {
    position: relative;
}
.bg-gradient-inner {
    background-image: url('../image/faq-image.webp');
    position: absolute;
    width: 2000px;
    height: 2000px;
    top: -565px;
    left: 372px;
    position: absolute;
    z-index: -1;
}
.faq-wrapper {
    position: relative;
    z-index: 2;
}
.faq-section-wrapper {
    padding-bottom: 90px;
}

.faq-heading {
    margin-bottom: 38px !important;
    padding-top: 68px !important;
}

.faq.expanded + .faq {
    border-top: 0;
}

.faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.faq-title h3 {
    color: var(--h1-color);
    font-size: 28px;
    font-weight: 400;
    height: 70px;
    line-height: 70px;
    pointer-events: none;
}

.faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 42px;
    height: 49px;
    justify-content: center;
    margin-right: 13px;
    width: 49px;
}

.faq-btn:hover,
.faq-title:hover {
    cursor: pointer;
}

.faq {
    padding-left: 20px;
    transition: background-color 0.2s linear, font-weight 0.2s linear, border-radius 0.2s linear;
    position: relative;
    margin-bottom: 25px;
}
.faq-background-outer {
    background-color: white;
    opacity: 0.3;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.faq-content {
    position: relative;
    z-index: 2;
}

.faq .faq-body {
    color: white;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 44px;
    border-top: 1px solid white;
    padding-top: 25px;
    width: 95%;
}

.faq.collapsed .faq-body {
    display: none;
}
.faq-body a {
    font-weight: bold;
    color: white;
    text-decoration: underline;
}

.faq-btn span {
    pointer-events: none;
}

.faq.collapsed .faq-btn span {
    color: white;
}

.faq.expanded .faq-btn span {
    color: white;
}

.faq.collapsed .faq-btn span.plus,
.faq.expanded .faq-btn span.minus {
    display: block;
}

.faq.collapsed .faq-btn span.minus,
.faq.expanded .faq-btn span.plus {
    display: none;
}

.faq.expanded .faq-title h3 {
    font-weight: 700;
}
.text-center {
    text-align: center;
    color: white;
}
.text-center p {
    padding-bottom: 50px;
    color: #fff;
}
.faq-title h3 {
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    align-items: center;
}
.faq-body p {
    color: white;
}

.contact-us-outer {
    position: relative;
}
.contact-us-outer-opacity {
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.15;
}
.contact-us-inner {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 60px 20px 70px;
}
.contact-us-inner h2 {
    font-size: var(--h2-size);
    color: white;
    font-weight: 700;
}
.contact-us-inner p {
    color: white;
    font-size: 15px;
    margin-bottom: 50px;
}
.contact-btn {
    background-color: #ea6989;
    border-radius: 69px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-top: 60px;
    padding: 20px 40px;
    position: relative;
}
/*
.contact-btn:hover:before{
    background: #000;
    border-radius: 22px;
    bottom: 0;
    color: #fff;
    content: "Services under development";
    font-size: 15px;
    font-weight: 300;
    justify-content: center;
    left: 0;
    opacity: .85;
    padding: 5px 15px;
    position: absolute;
    text-align: center;
    transform: translateY(-80px);
        height: inherit;
    width: inherit;
    width: -webkit-fill-available;
}

.contact-btn:hover:after{
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid black;
    clear: both;
    content: "";
    height: 0;
    left: 46%;
    opacity: .7;
    position: absolute;
    top: -1px;
    transform: translateY(-16px);
    width: 0;
}
*/
.contact-btn:hover {
    background-color: #ea6989;
}

h1 .uppercase {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 48px;
    /* line-height: 61px; */
    width: 1086px;
}
.header-lower {
    margin-bottom: 35px;
}
.header-lower-text {
    font-weight: 400;
    font-size: 30px;
    /* line-height: 38px; */
}

footer {
    background-color: #99c1e4;
}

footer .container {
    text-align: center;
    padding: 20px;
    border-top: 1px solid #d2e8fb;
}

footer .copyright {
    color: #828c98;
    font-size: 14px;
    color: white;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

.generate-title {
    margin-top: 38px;
}
.convert-title {
    margin-top: 52px;
}

@media only screen and (max-width: 1100px) {
    .nav-wrapper {
        width: 100%;
    }
}

/* TABLET VERSION */
@media only screen and (max-width: 994px) {
    .nav-wrapper ul {
        display: block;
    }
    .btn-lis a {
        justify-content: center;
        padding: 20px;
    }
    .nav-wrapper {
        width: 100%;
    }
    .nav-wrapper-bg {
        display: none;
    }
    .transparent-bg {
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.15;
        border-radius: 69px;
    }
    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }
    .best-marketplace-wrapper {
        flex-direction: column;
    }
    .best-marketplace-wrapper img {
        margin-bottom: 30px;
    }
    .image-wrapper {
        left: 65px;
    }
    .bg-gradient-inner {
        left: -170px;
    }
    .faq-section-wrapper {
        padding-bottom: 0;
    }
    .card-content ul li {
        color: #6f6c70;
    }
}

@media only screen and (max-width: 994px) {
}

/* RESPONSIVE */
@media only screen and (max-width: 767px) {
    header,
    article,
    footer {
        padding: 0px 22px;
    }
    article {
        padding: 0;
    }

    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 25px;
        line-height: 36px;
    }
    .h2-header {
        font-size: 25px;
        margin-top: 45px;
    }
    p {
        font-size: 15px;
        /* color: #fff; */
    }
    .site-logo {
        background-size: 175px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }

    .m-hidden {
        display: none;
    }

    .m-slide-down {
        -moz-transition: height 0.5s;
        -ms-transition: height 0.5s;
        -o-transition: height 0.5s;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
        height: 0;
        overflow: hidden;
    }

    .header-image {
        display: none;
    }

    .site-header-inner {
        padding: 26px 20px 50px;
    }

    .header-description {
        margin-bottom: 50px;
        max-width: 100%;
    }
    .header-lower-text {
        font-size: 25px;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
    }

    footer .container {
        flex-direction: column;
        padding-bottom: 24px;
    }

    footer .logo-wrapper {
        padding-bottom: 8px;
    }

    .image-wrapper {
        left: -160px;
    }
    .header-lower {
        margin-bottom: 25px;
    }
    li {
        position: relative;
    }
    h1 .uppercase {
        font-size: 30px;
        width: 100%;
    }

    .nav-wrapper {
        width: 100%;
    }
    .nav-wrapper-bg {
        display: none;
    }
    .transparent-bg {
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.15;
        border-radius: 69px;
    }
    .image-wrapper {
        left: -200px;
    }
    .draw-software-li a {
        z-index: 10;
        background-position: 33px;
    }
    .feature-li a {
        z-index: 10;
        background-position: 80px;
    }

    .cta-btns li:first-of-type a:before {
        left: 143px;
        top: 23px;
    }
    .cta-btns li:nth-of-type(2) a:before {
        left: -25px;
        top: 2px;
    }
    .cta-btns li:nth-of-type(3) a:before {
        left: -25px;
        top: 6px;
    }
    .cta-btns li:nth-of-type(4) a:before {
        left: -25px;
        top: 2px;
    }

    .btn-lis a {
        justify-content: center;
        padding: 20px;
        position: relative;
    }
    .btn-lis span {
        padding-left: 26px;
    }
    .cta-btns li:first-of-type span:before {
        left: 2px;
    }
    .cta-btns li:nth-of-type(2) span:before {
        left: 2px;
    }
    .cta-btns li:nth-of-type(3) span:before {
        left: 0;
    }
    .cta-btns li:nth-of-type(4) span:before {
        left: 0;
    }
    .cta-btns {
        padding: 0;
    }

    .navigation-articles-desktop {
        display: none;
    }
    .navigation-articles-mobile {
        display: inherit;
    }
    .card-content {
        text-align: center;
        border-radius: 20px;
        padding: 40px 33px;
        box-shadow: 0px 0px 25px 0px #00000014;
        margin-top: 40px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .card-content h3 {
        color: #595959;
    }
    .card-content p {
        color: #6f6c70;
    }
    .more {
        color: #6f6c70;
        font-size: 15px;
        display: none;
        transition: all 3s ease-in;
    }
    .text.show-more .more {
        display: block;
    }
    .text.show-more .dots {
        display: none;
    }
    .show-more-btn {
        transform: rotate(180deg);
        transition: all 0.3s ease-in;
    }

    .features {
        padding-left: 20px;
        padding-right: 20px;
    }
    .features-content {
        text-align: center;
    }
    .features-content-description p {
        color: #6f6c70;
    }
    .create-nft {
        padding-left: 20px;
        padding-right: 20px;
    }
    .steps li {
        width: 100%;
        text-align: left;
        margin-bottom: 12px;
        line-height: 20px;
        flex-direction: column;
        text-align: center;
    }
    .steps li svg {
        display: none;
    }
    .steps li p {
        margin-top: 5px;
    }
    .steps li img {
        margin-right: 15px;
    }

    .best-marketplace-wrapper,
    .steps {
        flex-direction: column;
    }
    .best-marketplace {
        padding-left: 20px;
        padding-right: 20px;
    }
    .best-marketplace-wrapper img {
        max-width: 456px;
        max-height: 210px;
        margin-bottom: 25px;
        margin-top: 25px;
        /* padding-left: 50px; */
    }
    .our-team-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

    .faq-wrapper {
        padding: 0 20px;
    }
    .bg-gradient-inner {
        left: -275px;
    }
    .articles {
        padding: 0 20px;
    }
    .click-for-more {
        background: url('../image/More.webp') no-repeat;
        background-position: center;
        height: 5.5em;
        width: 100%;
    }
    .m-readmore-btn {
        background: transparent;
        border: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        color: white;
        width: 100%;
        margin: 15px auto 50px;
    }
    .m-readmore-btn * {
        pointer-events: none;
    }
    .m-visible {
        display: initial;
    }
    .faq-wrapper-outer {
        padding-top: 50px;
    }
    .wrapper-inner {
        padding-bottom: 50px;
        padding-top: 40px;
    }
    .wrapper-inner h2 {
        font-size: var(--h2-size-mobile);
    }
    .features-headers {
        text-align: left;
    }
    .contact-btn {
        font-size: 17px;
        padding: 20px 60px;
    }
    .contact-btn:hover:before {
        bottom: -6px;
    }
    .check-text img {
        margin-top: 33px;
    }
}

@media only screen and (min-width: 768px) {
    .navigation-articles-mobile {
        display: none;
    }
}

@media only screen and (max-width: 530px) {
    .best-marketplace-wrapper img {
        object-fit: contain;
        max-width: 330px;
        margin-left: 30px;
        margin-bottom: 0;
        margin-top: 0;
    }
    .card-content {
        padding: 40px 22px;
    }
}
@media only screen and (max-width: 365px) {
    .best-marketplace-wrapper img {
        object-fit: contain;
        max-width: 280px;
        margin-left: 30px;
        margin-bottom: 0;
        margin-top: 0;
    }
    .image-wrapper {
        left: -300px;
    }
}
