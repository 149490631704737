@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('../font/Poppins-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('../font/Poppins-ThinItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../font/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../font/Poppins-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../font/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../font/Poppins-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../font/Poppins-BlackItalic.ttf') format('truetype');
}